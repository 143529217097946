import { ExternalLinkIcon } from '@heroicons/react/solid'
export default function Careers(){
    return (
        <div className="bg-indigo-400">
          <div className="max-w-md mx-auto text-center py-16 px-4 sm:max-w-2xl sm:py-24 sm:px-6 lg:px-8 lg:py-32">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              <span className="block text-white">Looking for a new career?</span>
              <span className="block text-indigo-900">We’re hiring.</span>
            </h2>
            <a
              href="#"
              className="mt-8 w-full inline-flex items-center justify-center py-3 px-5 bg-white border border-transparent rounded-md shadow-md text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
            >
              <span>See open positions</span>
              <ExternalLinkIcon className="ml-3 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </a>
          </div>
        </div>
    )
}