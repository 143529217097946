import { HashLink } from 'react-router-hash-link';

export default function Banner(){
    return (
        <div className="lg:relative bg-gray-50">
            <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
                    <span className="block xl:inline">Software to enrich your</span>{' '}
                    <span className="block text-indigo-600 xl:inline">online business</span>
                    </h1>
                    <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                    Make the right technology investments for your toughest business challenges.
                    </p>
                    <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                        <HashLink smooth to='/contact#GetStarted'
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                            >
                            Get started
                        </HashLink>
                    </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-white items-center justify-center h-full w-full  lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                <img
                    className="relative inset-0  mx-auto "
                    src="/dataAnimation.gif"
                    alt=""
                />
            </div>
        </div>
    )
}