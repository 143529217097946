import PageHeader from "../../components/page_header";
import Contact from "../../components/contact";
import Team from "./components/team";
import Values from "./components/values";

export default function Company() {
    return (
        <div className="bg-white">
            <PageHeader header="About us" subHeader=" We strategize, design, and build digital products, transform teams, and co-create
disruptive business models. We work as technology innovation partners with our clients, helping them
adapt and thrive in the digital era." />
            <Team />
            <Values />
            <Contact />
        </div>
    )
}