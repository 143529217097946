import { Disclosure } from '@headlessui/react'
import { useLocation, Link } from 'react-router-dom'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import NavRoutes from './nav_routes'

function Navbar() {
    const location = useLocation()

    const renderMenuItems = (isMobile) => {
        if(isMobile){
            return(
                <>
                {
                    NavRoutes.main.map((item)=>{
                       return( 
                        <Disclosure.Button
                            as="a"
                            key={`mobile_${item.path}`}
                            href={window.location.origin + item.path }
                            className={`${item.path === location.pathname? "bg-indigo-50 border-indigo-500" : "border-transparent" } text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                        >
                            {item.name}
                        </Disclosure.Button>
                        )
                    })
                }
                </>
            )
        }   
        return(
            <>
            {
                NavRoutes.main.map((item)=>{
                   return( 
                    <Link
                        key={item.path}
                        to={item.path}
                        className={`${item.path === location.pathname? "border-indigo-500": "border-transparent"} text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                    >
                        {item.name}
                    </Link>
                   )
                })
            }
            </>
        )
    }
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => {
        return (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                    <Link to='/'>
                        <img
                            className="h-10 w-auto"
                            src="logo.png"
                            alt="Rozafa Consulting logo"
                        />
                    </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {renderMenuItems(open)}
                </div>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {renderMenuItems(open)}
            </div>
          </Disclosure.Panel>
        </>
      )}}
    </Disclosure>
  )
}

export default Navbar