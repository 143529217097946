import {
  CogIcon,
  ChartPieIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  DatabaseIcon,
  ArchiveIcon
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

const services = [
  {
    name: 'API Development',
    description: 'We develop and maintain highly optimized and scalable APIs to handle large throughputs.',
    icon: CogIcon,
    path: '/services#ApiDevelpment'
  },
  {
    name: 'Database and cloud migrations',
    description: 'Seamlessly migrate your existing cloud infrastructure or databases with zero down time.',
    icon: DatabaseIcon,
    path: '/services#DatabaseMigrations'
  },
  {
    name: 'Data pipelines and analytics',
    description: 'Unlock the required synergy of data, tools and people to gain strategic advantages through advanced analytics',
    icon: ChartPieIcon,
    path: '/services#DataAnalytics'
  },
  {
    name: 'ECommerce',
    description: 'Crafting Ecommerce ecosystems built upon the best available technologies and practices.',
    icon: ArchiveIcon,
    path: '/services#ECommerce'
  },
  {
    name: 'Web Development',
    description: 'Empower your product and service offerings by creating digital solutions leveraging best technologies.',
    icon: GlobeAltIcon,
    path: '/services#WebDevelopment'
  },
  {
    name: 'Blockchain development',
    description: 'Our full-stack Blockchain developers help build next-generation decentralized solutions for you.',
    icon: CubeTransparentIcon,
    path: '/services#BlockchainDevelopment'
  },
]

export default function Services() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <Link to='/services'>
          {/* <h2 className="text-base font-semibold uppercase tracking-wider text-indigo-600">Deploy faster</h2> */}
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What we do
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          We build, modernize, and reinvent your software to help you surpass business objectives.
          From custom software development to migrating business systems to the cloud, we help you increase market share and gain a competitive edge.
          </p>
        </Link>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {services.map((service) => (
              <HashLink smooth to={service.path}>
                <div key={service.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                      
                        <span className="inline-flex items-center justify-center rounded-md bg-indigo-500 p-3 shadow-lg">
                          <service.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{service.name}</h3>
                      <p className="mt-5 text-base text-gray-500">{service.description}</p>
                    </div>
                  </div>
                </div>
              </HashLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
