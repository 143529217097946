import Contact from '../../components/contact'
import PageHeader from '../../components/page_header'

import Cards from './components/cards'



export default function Industries() {
  return (
    <div className="bg-white">
        <main>
            <PageHeader header="Industries we serve" subHeader="Our industry expertise is gained from countless hands-on experiences across functions, sectors and geographies. 
            Our Industry experts from around the globe pool their perspectives, capabilities and resources to discover new opportunities for your business." />
            <Cards />
            <Contact />
        </main>
    </div>
  )
}
