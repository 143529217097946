import PageHeader from "../../components/page_header"

const features = [
    {
      name: 'API development',
      anchorId: "ApiDevelopment",
      description:
        `We are experienced in developing and integrating RESTful APIs that allow for seamless communication between different software systems. 
        Our team of developers is experienced at developing custom APIs to suit your varying business models and ensure coherent, flexible, and consistent integration with as many systems as possible.`,
      imageSrc: '/api.png',
      imageAlt: 'Image of API Icon',
    },
    {
      name: 'Database and cloud migrations',
      anchorId: "DatabaseMigrations",
      description:
        `We understand that migrations can be scary and finding the right partners is key in this process. 
        Our engineers have years of industry experience working with a wide variety of technologies, and have performed countless migrations. Whether you are migrating to a new database or moving to the 
        cloud, you are in good hands.`,
      imageSrc: '/database2.png',
      imageAlt: 'Detail of zipper pull with tan leather and silver rivet.',
    },
    {
        name: 'Data pipelines and analytics',
        anchorId: "DataAnalytics",
        description:
        `
        Big data and advanced analytics offer profound strategic advantages, yet only a few companies truly unlock the required synergy of data, tools, people, and competitive focus. 
        At Rozafa, our team of data engineers, data analysts and data science consultants specialize in building internal capabilities for our clients to become analytics-driven 
        organizations that achieve better performance through data.
        `,
        imageSrc: '/data.png',
        imageAlt: 'Image of database icon.',
      },
      {
        name: 'ECommerce',
        anchorId: "ECommerce",
        description:
        `
        To furnish ecommerce success, we craft ecosystems that are built upon the best available technologies and practices. 
        Handpicking elements that fit your industry to serve your customer, retail model, growth, and ROI optimization.
        `,
        imageSrc: '/ecommerce.png',
        imageAlt: 'Image of ecommerce icon.',
      },
      {
        name: 'Web Development',
        anchorId: "WebDevelopment",
        description:
        `
        Modern web customers have come to expect meaningful digital experiences personalized for them. 
        Interfaces offering an intuitive UI and easy to digest UX tend to stand out in today's saturated market. By leveraging cutting edge technology, these interfaces can 
        be transformed into highly performant and robust web applications that boast scalability, security and reliability.
        `,
        imageSrc: '/webDevelopment.png',
        imageAlt: 'Image of a computer for web development',
      },
      {
        name: 'Blockchain development',
        anchorId: "BlockchainDevelopment",
        description:
        `
        Our blockchain expertise spans strategic assessment and road mapping, proofs-of-concept, blockchain development and implementation,
        helping you actualize the benefits from distributed blockchain technology. We have helped our clients in every industry to modernize and can bring that same expert insight and knowledge to your idea.
        `,
        imageSrc: 'blockchain.png',
        imageAlt: 'Image of crypto currence',
      },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Services() {
    return (
      <div className="bg-white">
        <PageHeader header="Services we provide" subHeader="We develop custom software solutions that are tailored to meet your business needs and unique challenges.
        From software discovery and consultation to the implementation of scalable solutions and long term support." />
        <div className="max-w-7xl mx-auto sm:px-6 px-8 py-16 sm:py-24 lg:py-24">
          <div className="mt-16 space-y-16">
            {features.map((feature, featureIdx) => (
              <div
                id={feature.anchorId}
                key={feature.name}
                className="flex flex-col-reverse sm:grid sm:grid-cols-12 sm:gap-x-8 sm:items-center"
              >
                <div
                className={classNames(
                    featureIdx % 2 === 0 ? 'sm:col-start-1': 'sm:col-start-6 xl:col-start-5',
                    'flex-auto sm:row-start-1 sm:col-span-7 xl:col-span-8'
                )}
              >
                <h3 className="text-xl font-medium text-gray-900">{feature.name}</h3>
                <p className="mt-2 text-lg text-gray-500">{feature.description}</p>
              </div>
              <div
                className={classNames(
                    featureIdx % 2 === 0 ? 'sm:col-start-8 xl:col-start-9' : 'sm:col-start-1',
                  'mt-6 sm:mt-0 sm:row-start-1 sm:col-span-5 xl:col-span-4'
                 
                )}
              >
                  <div className="aspect-w-2 aspect-h-2 rounded-md overflow-hidden">
                    <img src={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }