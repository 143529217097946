/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          indigo: colors.purple,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import PageHeader from '../../components/page_header'
import Careers from './components/careers'
import ContactForm from './components/contact_form'


export default function Contact() {
  return (
    <div className="bg-white">
      <main>
        {/* Header */}
        <PageHeader header="Get in touch" subHeader="" />

        {/* Contact Section */}
        <ContactForm />

        {/* CTA Section */}
        <Careers />
      </main>
    </div>
  )
}
