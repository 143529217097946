
export default function PageHeader(props){
    const {header, subHeader } = props
    
    return (
        <div className="py-20 bg-gray-50 sm:py-32">
          <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-3xl leading-10 font-extrabold tracking-tight text-gray-900 text-center sm:text-4xl sm:leading-none lg:text-5xl">
              {header}
            </h1>
            <p className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-gray-500 text-center">
              {subHeader}
            </p>
          </div>
        </div>
    )
}