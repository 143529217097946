
export default function TechStack(){

  const stack = [
    {
      src: '/aws.png',
      alt: 'AWS'
    },
    {
      src: '/node.png',
      alt: 'NodeJS'
    },
    {
      src: '/react.png',
      alt: 'React'
    },
    {
      src: '/postgres.png',
      alt: 'PostgreSQL'
    },
    {
      src: '/redis.png',
      alt: 'Redis'
    },
  ]
    return (
        
         <div className="bg-white">
         <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
           <p className="text-center text-md font-semibold uppercase text-gray-500 tracking-wide">
            Some of our favorite tools
           </p>
           <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            {stack.map((item)=>(
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-20"  src={item.src} alt={item.alt}/>
              </div>
            ))}
           </div>
         </div>
       </div>
    )
}