import Banner from "./components/banner"
import Services from "./components/services"
import About from "./components/about"
import Contact from "../../components/contact"
import TechStack from "./components/techStack"

export default function Home() {
  return (
    <div className="relative">
      <Banner />
      <About />
      <Services />
      <TechStack />
      <Contact />
      
    </div>
  )
}
