import { AcademicCapIcon, TrendingUpIcon, ClockIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';

const communicationFeatures = [
  {
    id: 1,
    name: 'Top Talent',
    description:
      'Our engineers are the best at what they do. We recruit engineers with working experience from top tech companies in the world.',
    icon: AcademicCapIcon,
  },
  {
    id: 2,
    name: 'Long Term Oriented Culture',
    description:
      'Our team is focused on building partnerships with our clients. We believing in growing with you.',
    icon: TrendingUpIcon,
  },
  {
    id: 3,
    name: 'Reliable and Transparent',
    description:
      'There is no such thing as over communication. We offer around the clock support for our clients.',
    icon: ClockIcon,
  },
]

export default function About() {
  return (
    <div className="py-16 bg-white overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

        {/* <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg> */}

        <div className="relative">
          <Link to='/company'>
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Who we are
            </h2>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            We strategize, design, and build digital products, transform teams, and co-create
disruptive business models. We work as technology innovation partners with our clients, helping them
adapt and thrive in the digital era.
            </p>
          </Link>
        </div>


        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              {/* <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg> */}
              <img
                className="relative mx-auto"
                width={490}
                src="/engineer.gif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
