import { useState } from 'react';
import { BadgeCheckIcon } from '@heroicons/react/solid'

export default function ContactForm(){
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLasttName ] = useState("")
    const [ company, setCompany ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ phoneNumber, setPhoneNumber ] = useState("")
    const [ message, setMessage ] = useState("")
    const [ budget, setBudget ] = useState("")
    const [ referredFrom, setReferredFrom ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ submitted, setSubmitted ] = useState(false)
    
    const handleRadioChange = (e)=>{
      setBudget(e.target.value)
    }
    const submitForm = async (data)=>{
      try{
        setLoading(true)
        const url = 'https://cite2n0b96.execute-api.us-east-1.amazonaws.com/ProcessContactFormSubmit'
        const config = {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
        await fetch(url, config)
        setLoading(false)
        setSubmitted(true)
      }catch(e){
        setLoading(false)
        setSubmitted(true)
      }
      
    }
    const handleSubmit = (e)=>{
      e.preventDefault()
      const data = {
        firstName,
        lastName,
        company,
        email,
        phoneNumber,
        message,
        budget,
        referredFrom
      }
      return submitForm(data).then((res)=>{
        console.log(res)
      }).catch((e)=>{
        
      })
    }
    return (
        <div id="GetStarted" className="relative bg-white">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                alt=""
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Let's work together</h2>
                {submitted? 
                (<div className='py-20 flex items-center justify-center text-center'>
                  <BadgeCheckIcon className='text-green-500 h-20 w-20' />
                  
                  <span>Thank you for submitting your inquiry. We will be in touch shortly</span>

                </div>):
                (<form onSubmit={(e)=>handleSubmit(e)} className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      required
                      value={firstName}
                      onChange={(e)=>setFirstName(e.target.value)}
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      value={lastName}
                      required
                      onChange={(e)=>setLasttName(e.target.value)}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      value={email}
                      required
                      onChange={(e)=>setEmail(e.target.value)}
                      type="email"
                      autoComplete="email"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company"
                      value={company}
                      required
                      onChange={(e)=>setCompany(e.target.value)}
                      id="company"
                      autoComplete="organization"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <span id="phone-description" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      value={phoneNumber}
                      onChange={(e)=>setPhoneNumber(e.target.value)}
                      id="phone"
                      autoComplete="tel"
                      aria-describedby="phone-description"
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700">
                      How can we help you?
                    </label>
                    <span id="how-can-we-help-description" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="how-can-we-help"
                      name="how-can-we-help"
                      value={message}
                      required
                      onChange={(e)=>setMessage(e.target.value)}
                      maxLength={500}
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <fieldset className="sm:col-span-2">
                  <legend className="block text-sm font-medium text-gray-700">Expected budget</legend>
                  <div className="mt-4 grid grid-cols-1 gap-y-4">
                    <div className="flex items-center">
                      <input
                        id="budget-under-25k"
                        name="budget"
                        required
                        onChange={(e)=>handleRadioChange(e)}
                        defaultValue="under_25k"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor="budget-under-25k" className="ml-3">
                        <span className="block text-sm text-gray-700">Less than $25K</span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-25k-50k"
                        name="budget"
                        onChange={(e)=>handleRadioChange(e)}
                        defaultValue="25k-50k"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor="budget-25k-50k" className="ml-3">
                        <span className="block text-sm text-gray-700">$25K – $50K</span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-50k-100k"
                        name="budget"
                        onChange={(e)=>handleRadioChange(e)}
                        defaultValue="50k-100k"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor="budget-50k-100k" className="ml-3">
                        <span className="block text-sm text-gray-700">$50K – $100K</span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-over-100k"
                        name="budget"
                        onChange={(e)=>handleRadioChange(e)}
                        defaultValue="over_100k"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor="budget-over-100k" className="ml-3">
                        <span className="block text-sm text-gray-700">$100K+</span>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div className="sm:col-span-2">
                  <label htmlFor="how-did-you-hear-about-us" className="block text-sm font-medium text-gray-700">
                    How did you hear about us?
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="how-did-you-hear-about-us"
                      value={referredFrom}
                      onChange={(e)=>setReferredFrom(e.target.value)}
                      id="how-did-you-hear-about-us"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center text-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {loading?
                    (<svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>):
                    (<></>)
                    }
                    Submit
                  </button>
                </div>
              </form>)
                }
                
              </div>
            </div>
          </div>
        </div>
    )
}